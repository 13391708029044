
export default function reviewGroupsReducer(state = [], action){
    switch (action.type) {
        case "set-review-groups":
            return action.data;
        case "delete-review-group":
            return state.filter((item)=>item.id != action.id)
        case "update-review-group":
            return state.map((item)=>item.id == action.id ? {...item, ...action.data} : item)
        default:
            return state;
    }
}
