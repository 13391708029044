import LoadableInner from "react-loadable";
import PageLoadingSpinner from "../old/form/PageLoadingSpinner";

const Loadable = ({loader}) => LoadableInner({
        loader,
        loading: PageLoadingSpinner,
        delay:1000,
})

export const AsyncDashboard = Loadable({
    loader: () => import('../../pages/dashboard'),
})

export const AsyncCustomize = Loadable({
    loader: () => import('../../pages/customize'),
})

export const AsyncOrders = Loadable ({
    loader: () => import('../../pages/orders'),
})

export const AsyncReviews = Loadable ({
    loader: () => import('../../pages/reviews'),
})

export const AsyncQA = Loadable ({
    loader: () => import('../../pages/qa'),
})

export const AsyncReports = Loadable ({
    loader: () => import('../../pages/reports'),
})

export const AsyncSettings = Loadable ({
    loader: () => import('../../pages/settings'),
})

export const AsyncPricing = Loadable ({
    loader: () => import('../../pages/pricing'),
})

export const AsyncEmailTemplateEditor = Loadable ({
    loader: () => import('../../pages/components/email-template-editor/EmailTemplateEditorIndex')
})

export const AsyncWidgetEditor = Loadable ({
    loader: () => import('../../pages/components/widget-editor/WidgetEditorIndex'),
})

export const AsyncFormBuilder = Loadable ({
    loader: () => import('../../pages/components/form-builder-widget/FormBuilder'),
})

export const AsyncGeneralSettings = Loadable ({
    loader: () => import('../../pages/components/settings/GeneralCard'),
})

export const AsyncCollectReviewsSettings = Loadable ({
    loader: () => import('../../pages/components/settings/CollectReviewsCard'),
})

export const AsyncDisplayReviewSettings = Loadable ({
    loader: () => import('../../pages/components/settings/DisplayReviewsCard'),
})

export const AsyncReferralSettings = Loadable ({
    loader: () => import('../../pages/components/settings/ReferralsCard'),
})

export const AsyncImportExportSettings = Loadable ({
    loader: () => import('../../pages/components/settings/ImportExportCard'),
})

export const AsyncAdvancedSettings = Loadable({
    loader: () => import('../../pages/components/settings/AdvancedSettings'),
})

export const AsyncIntegrationsSettings = Loadable ({
    loader: () => import('../../pages/components/settings/IntegrationsCard'),
})

export const AsyncCustomizeText = Loadable ({
    loader: () => import('../../pages/components/customize-page/CustomizeTextCard'),
})

export const AsyncNotificationSettings = Loadable({
    loader: () => import('../../pages/components/customize-page/NotificationsCard'),
})

export const AsyncSavedReports = Loadable({
    loader: () => import('../../pages/components/reports/SavedReports'),
})

export const AsyncMailReports = Loadable({
    loader: () => import('../../pages/components/reports/MailReports'),
})

export const AsyncRatingsReports = Loadable({
    loader: () => import('../../pages/components/reports/RatingsReports'),
})

export const AsyncReviewReports = Loadable({
    loader: () => import('../../pages/components/reports/ReviewReports'),
})

export const AsyncSentimentReports = Loadable({
    loader: () => import('../../pages/components/reports/SentimentReports'),
})

export const AsyncCouponReports = Loadable({
    loader: () => import('../../pages/components/reports/CouponReports'),
})

export const AsyncReviewMain = Loadable({
    loader: () => import('../../pages/components/reviews/ReviewMain'),
})

export const AsyncReviewProducts = Loadable({
    loader: () => import('../../pages/components/reviews/ReviewProducts'),
})

export const AsyncReviewProductItem = Loadable({
    loader: () => import('../../pages/components/reviews/ReviewProductItem'),
})

export const AsyncReviewProductAnalyticsStats = Loadable({
    loader: () => import('../../pages/components/reviews/products/AnalyticsCard'),
})

export const AsyncReviewProductReviewStats = Loadable({
    loader: () => import('../../pages/components/reviews/products/ReviewStatsCard'),
})

export const AsyncReviewProductMediaStats = Loadable({
    loader: () => import('../../pages/components/reviews/products/MediaCard'),
})

export const AsyncReviewProductAttributesStats = Loadable({
    loader: () => import('../../pages/components/reviews/products/AttributesCard'),
})

export const AsyncQuestionsAll = Loadable({
    loader: () => import('../../pages/components/qa/QADefault'),
})

export const AsyncQuestionsAnswered = Loadable({
    loader: () => import('../../pages/components/qa/QAAnswered'),
})

export const AsyncQuestionsUnanswered = Loadable({
    loader: () => import('../../pages/components/qa/QAUnanswered'),
})

export const AsyncCustomizeWidgetEditor = Loadable({
    loader: () => import('../../pages/components/customize-page/WidgetEditor'),
})