import React, {useEffect} from 'react'
import Header from "./components/Header";
import Footer from "./components/Footer";
import KitchenSink from "./kitchen-sink/KitchenSink";
import {Route, Switch} from 'react-router-dom'
import AdminPanelLayout from "./components/layout/AdminPanelLayout";
import NavBar from "./components/NavBar";
import LazyInstallers from "./pages/installers/LazyInstallers";
import {getAccessToken} from "./api";
import {loadSettings} from "./redux-store/settings/settingsAction";
import {useDispatch} from "react-redux";
import { AsyncDashboard, AsyncCustomize, AsyncWidgetEditor, AsyncEmailTemplateEditor, AsyncFormBuilder, AsyncSettings, AsyncReports, AsyncReviews, AsyncPricing, AsyncQA } from "./components/extras/AsyncLoader";
import LazyOnboardingIndex from "./pages/onboarding/LazyOnboardingIndex";
import LazyBillingIndex from "./pages/billing/LazyBillingIndex";

const App = () => {
    const token = getAccessToken()
    const dispatch = useDispatch()

    useEffect(() => {
        if( process.env.NODE_ENV === "development" || token) {
            dispatch(loadSettings())
        }
    },[token])

    return (
        <section className={"min-vh-100 d-flex flex-column"}>
            <Header />
            <div style={{flex:"1 1 auto"}} className={"bg-light d-flex"}>
                {
                    process.env.NODE_ENV === "development" || token
                        ? <Switch>
                            <Route path={'/customize/widget-editor/:widgetType/edit'} component={AsyncWidgetEditor}/>
                            <Route path={'/customize/widget-editor/form-builder/customize'} component={AsyncFormBuilder}/>
                            <Route path={"/wizard"} component={LazyOnboardingIndex}/>
                            <Route path={"/install"} component={LazyInstallers}/>
                            <Route path={'/customize-template/:id'} component={AsyncEmailTemplateEditor}/>
                            <Route path={'/pricing'} component={AsyncPricing}/>
                            <Route path={"/billing"} component={LazyBillingIndex}/>
                            <Route component={WithNavBar}/>
                        </Switch>
                        : <InstallComponent />
                }
            </div>
            <Footer />
        </section>
    )
}

function InstallComponent(){
    return <p>Open the app in your store admin to access the admin panel</p>
}

function WithNavBar(){
    return (
        <main className={"d-flex"} style={{flex:'1 1 auto'}}>
            <NavBar />
            <Switch>
                <AdminPanelLayout>
                    <Route exact path={'/'} component={AsyncDashboard} />
                    <Route path={'/dashboard'} component={AsyncDashboard} />
                    <Route path={'/customize'} component={AsyncCustomize} />
                    <Route path={'/reviews'} component={AsyncReviews} />
                    <Route path={'/q&a'} component={AsyncQA} />
                    <Route path={'/reports'} component={AsyncReports} />
                    <Route path={'/settings'} component={AsyncSettings} />
                    <Route path={'/kitchen-sink'} component={KitchenSink} />
                </AdminPanelLayout>
            </Switch>
        </main>
    )
}

export default App
