import React, {useEffect, useState} from 'react'
import {
    CgFileDocument,
    FiSettings,
    IoAnalytics,
    IoPricetagsOutline,
    RiDashboardFill,
    RiQuestionAnswerLine,
    AiOutlineLink
} from "react-icons/all";
import {Link, useHistory} from 'react-router-dom'
import FormattedOption from "./old/form/FormattedOption";
import {Menu, MenuItem, ProSidebar, SubMenu} from 'react-pro-sidebar';
import {IoIosArrowDropleftCircle, IoIosArrowDroprightCircle} from "react-icons/io";

const navigationItems = [
    {
        title:'Dashboard',
        route:"/dashboard",
        Icon: RiDashboardFill,
        target: "dashboard-nav"
    },
    {
        title:'Customize',
        route:"/customize",
        Icon: CgFileDocument,
        target: "reviews-nav",
        subNav: [
            {
                title: "Widgets",
                route: '/customize/widget-editor'
            },
            {
                title: "Notifications",
                route: '/customize/notifications'
            },
            {
                title: "Review Form",
                route: '/customize/review-form'
            },
            {
                title: "Review Groups",
                route: '/customize/review-groups'
            },
            // {
            //     title: "Customize Text",
            //     route: '/customize/customize-text'
            // }
        ]
    },
    {
        title:'Reviews',
        route:"/reviews",
        Icon: CgFileDocument,
        target: "reviews-nav",
        subNav: [
            {
                title: "Moderation",
                route: '/reviews'
            },
            {
                title: "Products",
                route: '/reviews/products'
            },
            {
                title: "Attributes",
                route: '/reviews/products'
            },
            {
                title: "Groups",
                route: '/reviews/products'
            }
        ]
    },
    {
        title:'Q&A',
        route:"/q&a",
        Icon: RiQuestionAnswerLine,
        target: "qa-nav",
        subNav: [
            {
                title: "All",
                route: '/q&a'
            },
            {
                title: "Answered",
                route: '/q&a/answered'
            },
            {
                title: "Unanswered",
                route: '/q&a/unanswered'
            }
        ]
    },
    {
        title:'Reports',
        route:"/reports",
        Icon: IoAnalytics,
        target: "reports-nav"
    },
    {
        title:'Settings',
        route:"/settings",
        Icon: FiSettings,
        target: "settings-nav",
        subNav: [
            {
                title: "General",
                route: '/settings/general'
            },
            {
                title: "Collect Reviews",
                route: '/settings/collect-reviews'
            },
            {
                title: "Referrals",
                route: '/settings/referrals'
            },
            {
                title: "Import / Export",
                route: '/settings/import-export'
            },
            {
                title: "Advanced",
                route: '/settings/advanced'
            },
            {
                title: "Integrations",
                route: '/settings/integrations'
            }
        ]
    },
    {
        title:'Upgrade',
        route:"/pricing",
        Icon: IoPricetagsOutline,
        target: "pricing-nav"
    }
]

const NavBar = () => {
    const [isNavBarCollapsed, setIsNavBarCollapsed] = useState(false)

    return (
        <>
            <ProSidebar className={"d-none proNavBar d-lg-block border-end text-dark shadow-sm"} collapsed={isNavBarCollapsed}>
                <Menu popperArrow={true}>
                    {navigationItems.map(({ title, route, Icon, target, subNav }) => {
                        return (subNav)
                            ? (<SubMenu className={target} title={title} icon={<Icon style={{fontSize: "x-large"}} />}>
                                {subNav.map(({ title, route }) => <NavBarMenuItem title={title} Icon={AiOutlineLink} pageRoute={route} />)}
                            </SubMenu>)
                            : (<NavBarMenuItem title={title} Icon={Icon} pageRoute={route} joyrideTarget={target} iconFontSize={"x-large"} />)
                    })}
                </Menu>
            </ProSidebar>

            <div className={"btn d-flex align-items-center p-0 d-none d-lg-inline-flex"} onClick={() => setIsNavBarCollapsed(!isNavBarCollapsed)}>
                {(isNavBarCollapsed)
                    ? (<IoIosArrowDroprightCircle style={{fontSize: "x-large", marginLeft: '-0.85rem', zIndex: 2000}} className={"position-absolute"} />)
                    : (<IoIosArrowDropleftCircle style={{fontSize: "x-large", marginLeft: '-0.85rem', zIndex: 2000}} className={"position-absolute"} />)
                }
            </div>
        </>
    )
}

const NavBarMenuItem = ({ title, Icon, pageRoute, joyrideTarget, iconFontSize }) => {
    return <MenuItem icon={<Icon style={{fontSize: iconFontSize}} />}>
        {title}
        <Link to={pageRoute} className={`text-decoration-none ${joyrideTarget}`} />
    </MenuItem>
}

export function NavBarForMobile(){
    const [currentRoute, setRoute] = useState('');
    const history = useHistory()
    useEffect(()=>{
        if(currentRoute) {
            history.push(currentRoute)
        }
    },[currentRoute])
    return <nav className={"bg-light d-block d-lg-none p-2 shadow-sm"}>
        <select className={"form-select"} value={currentRoute} onChange={(e)=>setRoute(e.target.value)}>
            {
                navigationItems.map((item) => <FormattedOption id={item.title} value={item.route} key={item.title} />)
            }
        </select>
    </nav>
}

//Old options format for mobile, now replaced with formatted options
//<option value={item.route} key={item.title}>{item.title}</option>

export default NavBar
