import React, {useState} from 'react'
import {AiOutlineRight, MdDragIndicator} from "react-icons/all";
import {Collapse} from "reactstrap";
import {Draggable} from "react-beautiful-dnd";

const FormBuilderCollapse = ({ title = "Section", children, className, id, arrayIndex }) => {
    const [collapse, setCollapse] = useState(false)

    return <Draggable draggableId={id.toString()} index={arrayIndex}>
        {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.draggableProps} isDragging={snapshot.isDragging}>
                <section className = {`btn w-100 ${className}`} onClick = {() => setCollapse(!collapse)} style={{backgroundColor: `${snapshot.isDragging ? 'lightgreen' : 'white'}`}}>
                    <div className={"d-inline-flex float-start mt-2"}>
                        <div {...provided.dragHandleProps}>
                            <MdDragIndicator style={{fontSize: "larger"}} />
                        </div>

                        <h5 className={"ms-3"}>{title}</h5>
                    </div>

                    <AiOutlineRight className={"float-end mt-2"} style = {{fontSize: "larger", transform: `${collapse ? 'rotate(90deg)' : 'rotate(0deg)'}`, transition: 'transform 75ms linear'}} />
                </section>

                <Collapse isOpen={collapse}>
                    <div className={"container-fluid mb-3"}>
                        {children}
                    </div>
                </Collapse>
            </div>
        )}
    </Draggable>
}

export default FormBuilderCollapse