import React, {useState} from "react";
import LazyRangePicker from "../../components/datepickers/LazyRangePicker";
import dayjs from "dayjs";
import FormattedOption from "../../components/old/form/FormattedOption";
import ReactSwitch from "react-switch";
import {CgAsterisk} from "react-icons/all";

const Mandatory = ({ isMandatory }) => {
    if (isMandatory)
        return <sup className={"text-danger"}>
            <CgAsterisk style={{fontSize: "larger"}} />
        </sup>
    else
        return null
}

export const FormBuilderInputField = ({ title, placeholder, className, type, mandatory = false }) => {
    const [input, setInput] = useState('')

    return <>
        <h6 className={"d-inline-block"}>
            {title}
            <Mandatory isMandatory={mandatory} />
        </h6>

        <input className={`form-control bg-light p-2 ${className}`} type={type} value={input} placeholder={placeholder} onChange={(e) => setInput(e.target.value)} />
    </>
}

export const FormBuilderTextArea = ({ title, placeholder, className, mandatory = false }) => {
    const [input, setInput] = useState('')

    return <>
        <h6 className={"d-inline-block"}>
            {title}
            <Mandatory isMandatory={mandatory} />
        </h6>

        <div>
            <textarea className={`form-control bg-light w-100 ${className}`} value={input} placeholder={placeholder} onChange={(e) => setInput(e.target.value)} />
        </div>
    </>
}

export const FormBuilderDatePicker = ({ title, mandatory = false }) => {
    const [dates, setDates] = useState([
        dayjs().startOf('month').toDate(),
        new Date()
    ])

    return <>
        <h6 className={"d-inline-block"}>
            {title}
            <Mandatory isMandatory={mandatory} />
        </h6>

        <LazyRangePicker onChange={setDates} value={dates} />
    </>
}

export const FormBuilderDropdown = ({ title, className, option, placeholder, mandatory = false }) => {
    const [input, setInput] = useState('')

    return <>
        <h6 className={"d-inline-block"}>
            {title}
            <Mandatory isMandatory={mandatory} />
        </h6>

        <select value={input} className = {`form-select bg-light ${className}`} onChange={(e) => setInput(e.target.value)}>
            <option disabled selected hidden value = {"default"}>{placeholder}</option>
            {option.map(({ label, value }) => <FormattedOption id={value} value={label} key={value} />)}
        </select>
    </>
}

export const FormBuilderInputCR = ({ title, type, className }) => {
    const [input, setInput] = useState('')

    return <>
        <input className={`me-2 ${className}`} type={type} value={input} onChange={(e) => setInput(e.target.value)} />
        <span>{title}</span>
    </>
}

export const FormBuilderSwitchBtn = ({ title }) => {
    const [isSwitchActive, setIsSwitchActive] = useState(true)

    return <div className={"d-flex align-items-center"}>
        <ReactSwitch checked={isSwitchActive} onChange={(checked) => setIsSwitchActive(checked)} />
        <span className={"ms-3"}>{title}</span>
    </div>
}