
const defaultTemplate = {
    subject:'',
    bodySections:[]
};

export default (state = defaultTemplate, action)=>{
    switch (action.type) {
        case "set-email-template":
            return action.data;
        case "reorder-email-template-bodysections":
            const {sourceIndex, destinationIndex} = action;
            return {
                ...state,
                bodySections: reorder(state.bodySections, sourceIndex, destinationIndex)
            }
        case "update-subject":
            return {
                ...state,
                subject: action.subject
            }
        case "remove-body-section":
            return {
                ...state,
                bodySections: state.bodySections.filter((item)=>item.id !== action.id)
            }
        case "update-bodysection-prop":
            const {id, change} = action
            return {
                ...state,
                bodySections: state.bodySections.map((item)=>{
                    return item.id === id ? {
                        ...item,
                        values: {
                            ...item.values,
                            ...change,
                        }
                    }: item
                })
            }
        default:
            return state;
    }
}

const reorder = (list, startIndex, endIndex) => {
    if(startIndex!==undefined && endIndex !== undefined) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }else{
        return list;
    }
};
