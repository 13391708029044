import React from 'react';
import Spinner from "../../old/form/Spinner";

function PageLoadingSpinner(props) {
    return (
        <div className={"text-center d-flex align-items-center justify-content-center"} style={{height:300, width:'100%'}}>
            <Spinner />
        </div>
    );
}

export default PageLoadingSpinner;
