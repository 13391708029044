import React, {useState} from "react";
import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import FormBuilderCollapse from "./FormBuilderCollapse";
import {MdOutlineDashboardCustomize} from "react-icons/md";
import FormBuilderConfigButton from "./FormBuilderConfigButton";
import FormBuilderDraggableConfigButton from "./FormBuilderDraggableConfigButton";
import FormBuilderSectionLayout from "./FormBuilderSectionLayout";
import { suggestedField, dragAndDropField, formSections, formFields } from "./data/data";
import FormBuilderConfigLayout from "./FormBuilderConfigLayout";

const KitchenSink = () => {
    const [listOfSections, updateListOfSections] = useState(formSections)
    const [listOfFormFields, updateListOfFormFields] = useState(formFields)

    const onListUpdate = () => {
        updateListOfSections([
            ...listOfSections,
            {
                id: Math.random() + 9,
                title: "New Section"
            }
        ])
    }

    const onDragEnd = ({ source, destination }) => {
        if(!destination)
            return

        if (destination.droppableId === source.droppableId && destination.index === source.index)
            return

        const newListOfSections = Array.from(listOfSections)
        const [ removedSection ] = newListOfSections.splice(source.index, 1)
        newListOfSections.splice(destination.index, 0, removedSection)

        updateListOfSections([...newListOfSections])
    }

    return <DragDropContext onDragEnd={onDragEnd}>
        <section className = {"py-3 px-2"}>
            <div className={"card h-100"}>
                <div className={"card-body p-0"}>
                    <div className={"float-start"} style={{width: '65%'}}>
                        <h4 className={"border-bottom ps-3 py-2 card-header"}>
                            Form Layout

                            <button className={"btn ms-2 float-end btn-sm btn-primary"}>Save</button>
                            <button className={"btn float-end btn-sm btn-outline-danger"}>Cancel</button>
                        </h4>

                        <div className={"p-2 ps-3"}>
                            <Droppable droppableId={"1"}>
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {listOfSections.map(({ title, id }, index) => <FormBuilderCollapse title={title} id={id} key={id} arrayIndex={index}>
                                            <div className={"p-4 pt-0 border-top border-bottom border-3 border-dark"}>
                                                <div className={"row"}>
                                                    {listOfFormFields.map(({ inputField, datePicker, dropdown, inputCR, textArea, switchBtn }) => <FormBuilderSectionLayout inputField={inputField} datepicker={datePicker} dropdown={dropdown} inputCR={inputCR} textArea={textArea} switchBtn={switchBtn} />)}
                                                </div>
                                            </div>
                                        </FormBuilderCollapse>)}

                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </div>

                    <div className={"float-end border-start"} style={{width: '35%'}}>
                        <h4 className={"border-bottom ps-3 py-2 card-header"}>Configure</h4>

                        <div className={"p-2 ps-3"}>
                            <>
                                <div className={"mt-1 mb-3 mx-4"}>
                                    <FormBuilderConfigButton onClick={onListUpdate} className={"text-center"} buttonTitle={"Add Section"} Icon={MdOutlineDashboardCustomize} iconSize={"x-large"} />
                                </div>

                                <div className={"border-top pt-3 mb-3"}>
                                    <FormBuilderConfigLayout heading={"Drag & Drop Field"}>
                                        <div className={"row"}>
                                            {dragAndDropField.map(({ id, title, icon, iconSize }) => <div className={"col-12 col-md-6 mt-3"} key={id}>
                                                <FormBuilderConfigButton buttonTitle={title} Icon={icon} iconSize={iconSize} />
                                            </div>)}
                                        </div>

                                        {/*<Droppable droppableId={"2"}>*/}
                                        {/*    {(provided) => <section ref={provided.innerRef} {...provided.droppableProps}>*/}
                                        {/*        <div className={"row"}>*/}
                                        {/*            {dragAndDropField.map(({ id, title, icon }, index) => <FormBuilderDraggableConfigButton key={id} id={id} arrayIndex={index} buttonTitle={title} Icon={icon} />)}*/}
                                        {/*        </div>*/}
                                        {/*    </section>}*/}
                                        {/*</Droppable>*/}
                                    </FormBuilderConfigLayout>
                                </div>

                                <div className={"border-top py-3"}>
                                    <FormBuilderConfigLayout heading={"Suggested Field"}>
                                        <div className={"row"}>
                                            {suggestedField.map(({ id, title, icon, iconSize }) => <div className={"col-12 col-md-6 mt-3"} key={id}>
                                                <FormBuilderConfigButton buttonTitle={title} Icon={icon} iconSize={iconSize} />
                                            </div>)}
                                        </div>
                                    </FormBuilderConfigLayout>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </DragDropContext>
}

export default KitchenSink