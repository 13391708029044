import React from 'react'
import {Link} from 'react-router-dom'
import {FormattedMessage} from "react-intl";
import {availableLanguages} from "../locale/locale";
import useSettings from "../redux-store/useSettings";
import FormattedOption from "./old/form/FormattedOption";

const Header = () => {
    return (
        <header className={'bg-dark text-white py-2 px-3 d-flex justify-content-between align-items-center'}>
            <Link to={'/'} style={{textDecoration: 'none'}}>
                <span className={'btn px-0 d-flex'}>
                    <img
                        alt={'logo'}
                        className="rounded"
                        style={{ width: 35, height: 35 }}
                        src={'/icon-square.svg'}
                    />
                    <div className={'d-flex align-items-center ps-3 text-white'}>
                        <FormattedMessage id={"GOAFFPRO"} defaultMessage={"GOAFFPRO"} />
                    </div>
                </span>
            </Link>

            <LangSelector />
        </header>
    )
}

function LangSelector(){
    const [data, onChange] = useSettings()
    return <select className={"form-select-sm"}
                   value={data.defaultAdminLocale} onChange={onChange('defaultAdminLocale')}
                   style={{width:100}}>
        {
            availableLanguages.map(({label, locale}) => <FormattedOption id={label} value={locale} key={locale} />)
        }
    </select>
}

export default Header