import React from "react";

const steps = [
    {
        target: '.proNavBar',
        content: 'This is your Navbar. Use it to access different pages'
    },
    {
        target: '.dashboard-nav',
        content: 'This is your dashboard'
    },
    {
        target: '.reviews-nav',
        content: 'Here you can check your reviews'
    },
    {
        target: '.qa-nav',
        content: 'Here you can find Questions and Answers'
    },
    {
        target: '.reports-nav',
        content: 'Here you can generate reports for your reviews'
    },
    {
        target: '.settings-nav',
        content: 'Here you can personalise your settings'
    },
    {
        target: '.pricing-nav',
        content: 'Upgrade your account to get all the features'
    },
    {
        target: '.review-details',
        content: 'Find all the details about your reviews'
    },
    {
        target: '.ratings-details',
        content: 'All the list of ratings'
    },
    {
        target: '.revenue-details',
        content: 'Find all the details about your reviews'
    },
    {
        target: '.additional-details',
        content: 'Take a look at additional settings'
    }
]

export default steps