import axios from 'axios';
import * as localStorage from './utils/storage';
export const baseURL = process.env.NODE_ENV === "development"? 'https://api-staging.ratinghelper.com': 'https://api-staging.ratinghelper.com';
//export const baseURL = process.env.NODE_ENV === "development"? 'http://localhost:3005': 'https://api-staging.ratinghelper.com';

const authToken = process.env.NODE_ENV === "development" ? "8a7ddc99190bda985c618e6359874c2dc922e262159affaa27e012f733a33324" : null
export const appURL = process.env.NODE_ENV === "development"? 'http://localhost:4000': 'https://api-staging.ratinghelper.com';


export function getAccessToken(){
  return localStorage.getItem("access_token");
}

export function getAccessHeader(){
    console.log('getting token', getAccessToken())
    if(localStorage.getItem("access_token")){
        return {
            Authorization:"Bearer "+getAccessToken()
        }
    }else{
        return {
            'x-admin-token':authToken
        }
    }
}
export function getHttpClient(config={}){
    console.log({headers:getAccessHeader()})
    return axios.create({
        baseURL,
        headers:getAccessHeader(),
        ...config
    });
}
export function doQuery(query, variables){
    return getHttpClient().post('/v1/admin/graphql',{
        query,
        variables
    }).then(({data})=>data)
}
export function doGraphQlQuery(query, variables){
       return doQuery(query, variables).then((data)=>data.data)
}
export const swrFetcher = url => getHttpClient().get(url).then(res => res.data)

//Header was -> 'Authorization': 'Bearer '+getAccessToken() -> now changed to -> 'x-admin-token': '8a7ddc99190bda985c618e6359874c2dc922e262159affaa27e012f733a33324'
// token ? {'Authorization':`Bearer ${token}`} : {};
