export default (state = {}, action)=>{
    switch (action.type) {
        case "set-theme":
            return action.data;
        case "update-theme":
            return {
                ...state,
                ...action.change
            }
        default:
            return state;
    }
}
