import {doGraphQlQuery} from '../../api';


export const ACTIONS = {
    merge:'update-settings',
};
export const actionMergeSetting = (data)=>{
    return {
        type:ACTIONS.merge,
        data
    }
};

export const updateSetting = (change)=>{
  return (dispatch, getState)=>{
      const original = getState().settings;
      dispatch(actionMergeSetting({
          ...change, saving:true
      }));
    return doGraphQlQuery(`mutation ($change: JSON) {
             updateStoreConfig (change: $change)
        }`,{
            change
        }).then((data)=>{
            dispatch(actionMergeSetting({saving:false}));
            return data;
        }).catch((e)=>{
            const originalObject = {};
            Object.keys(change).forEach((key)=>{
                originalObject[key] = original[key];
            });
            dispatch(actionMergeSetting({
                ...originalObject,
                saving:false,
            }));
        })
  }
};


export const loadSettings = ()=>{
    return (dispatch, getState)=>{
        dispatch(actionMergeSetting({loading:true, under_maintenance:false}));
        return doGraphQlQuery(`query {
             storeConfig
             widgetTheme
        }`).then((data)=>{
            dispatch(actionMergeSetting({
                ...data.storeConfig,
                loading:false
            }))
            dispatch({
                type:'set-theme',
                data: data.widgetTheme
            })
        }).catch(()=>{
            dispatch(actionMergeSetting({loading:false, under_maintenance:true}))
        })
    }
};
