import {ACTIONS} from "./settingsAction";
import * as localStorage from '../../utils/storage';

const defaultSettings = {
  access_token: localStorage.getItem("access_token"),
  marketingIntroCompleted: localStorage.getItem("marketingIntroCompleted"),
};

export default (state = defaultSettings, action)=>{
  switch (action.type) {
      case ACTIONS.merge:
        return {...state, ...action.data};
    default:
      return state;
  }
}
