import React from 'react'
import Skeleton from "react-loading-skeleton";
import {FormattedMessage} from "react-intl";

function LazyLoadingBar({error, pastDelay}) {
    if (error) {
        return <div>
            <FormattedMessage id={"Error!"} defaultMessage={"Error!"} />
            <button className={"btn btn-link text-danger"} onClick={()=>window.location.reload() }>
                <FormattedMessage id={"Reload App"} defaultMessage={"Reload App"} />
            </button>
        </div>
    } else if(pastDelay) {
        return <div><Skeleton height={64} /></div>
    }else{
        return null
    }
}

export default LazyLoadingBar