import React from 'react'
import {NavBarForMobile} from "../NavBar";

const AdminPanelLayout = ({ children }) => {
    return (
        <main className="d-flex flex-column" style={{flex: 1}}>
            <NavBarForMobile />
            <section className={"container-fluid mb-4 ps-4"}>
                {children}
            </section>
        </main>
    )
}

export default AdminPanelLayout
