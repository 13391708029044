import React from 'react'
import {FormattedMessage} from "react-intl";

const Footer = () => {
    return (
        <footer className="d-flex justify-content-center bg-dark text-white font-monospace">
            <p className={"pt-3 pb-1"}>
                <FormattedMessage id={"Copyright"} defaultMessage={"Copyright"} /> © {new Date().getFullYear()} <FormattedMessage id={"All rights reserved"} defaultMessage={"GoAffPro, Inc. All rights reserved."} />
            </p>
        </footer>
    )
}

export default Footer