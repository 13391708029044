import React, {useEffect, useState} from 'react'
import ReactDOM from 'react-dom'
import App from "./app";
import '../src/styles/globals.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/sidebar.scss'
import {BrowserRouter} from "react-router-dom";
import RelayEnvironment from './utils/RelayEnvironment'
import {IntlProvider} from "react-intl";
import {Provider, useSelector} from "react-redux";
import store from "./redux-store/store";
import {localeMap} from "./locale/locale";
import { Toaster } from 'react-hot-toast';
import Joyride from "react-joyride";
import JoyrideSteps from "./components/extras/JoyrideSteps";

const {RelayEnvironmentProvider} = require('react-relay');

const IntlProviderLocalization = ({ children }) => {
    const [messages, setMessages] = useState(null)

    const locale = useSelector((state) => {
        const l = state.settings.defaultAdminLocale
        return localeMap[l] ? l : 'en'
    })
    console.log('locale', locale)
    console.log('locale map', localeMap[locale], localeMap)

    useEffect(() => {
        console.log('locale outside effect', localeMap[locale], localeMap)
        if(localeMap[locale]) {
            console.log('locale','calling function')
            localeMap[locale]().then((messages)=>{
                console.log('locale got messages', messages)
                setMessages(messages)
            })
                .catch((e)=>{
                    console.log('locale got error', e)
                })
        }
    }, [locale])

    return (
        <IntlProvider locale={locale} messages={messages} onError={()=>{
            //disable error logging for now.
            //enable error collection in localstorage to extract for untranslated strings
        }}>
            {
                children
            }
        </IntlProvider>
    )
}

const AppTour = () => {
    const [isJoyrideActive, setIsJoyrideActive] = useState(true)

    useEffect(() => {
        if(window.localStorage.getItem("joyride-inactive") === 'tour-complete')
            setIsJoyrideActive(false)
    }, [isJoyrideActive])

    return <Joyride
        steps={JoyrideSteps}
        run={isJoyrideActive} continuous={true}
        showProgress={true}
        showSkipButton={true}
        styles={{
            options: {
                zIndex: 9000
            }
        }}
        callback={(e) => {
        if (e.action === 'reset')
            window.localStorage.setItem("joyride-inactive", "tour-complete")
        }}
    />
}

ReactDOM.render(
    <RelayEnvironmentProvider environment={RelayEnvironment}>
        <Provider store={store}>
            <IntlProviderLocalization locale={"en"}>
                <BrowserRouter>
                    <AppTour />
                    <Toaster />
                    <App />
                </BrowserRouter>
            </IntlProviderLocalization>
        </Provider>
    </RelayEnvironmentProvider>,
    document.getElementById('root')
)