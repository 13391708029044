import {useDispatch, useSelector} from "react-redux";
import {updateSetting} from "./settings/settingsAction";


export default function useSettings(){
    const data = useSelector((state)=>state.settings);
    const dispatch = useDispatch()
    const onChange = key => e => {
        const value = !e.target ? e : e.target.type === "checkbox" ? e.target.checked : e.target.value
        return updateSettings({
            [key]:value
        })
    }
    function updateSettings(change){
        dispatch(updateSetting(change))
    }
    return [data, onChange, updateSettings]
}
