import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import { doQuery} from "../api";

async function fetchRelay(params, variables) {
    return doQuery(params.text, variables);
}

export default new Environment({
    network: Network.create(fetchRelay),
    store: new Store(new RecordSource()),
});
