const options = {
    language: [
        {label: 'en', value: 'English'},
        {label: 'es', value: 'Spanish'},
        {label: 'fr', value: 'French'},
        {label: 'it', value: 'Italian'},
        {label: 'de', value: 'German'},
        {label: 'id', value: 'Indonesian'},
        {label: 'nl', value: 'Dutch'},
        {label: 'pt', value: 'Portugese'},
        {label: 'tr', value: 'Turkish'},
        {label: 'ru', value: 'Russian'},
        {label: 'ja', value: 'Japanese'},
        {label: 'zh-cn', value: 'Chinese (Simplified)'},
        {label: 'zh-tw', value: 'Chinese (Traditional)'},
        {label: 'sv', value: 'Swedish'},
        {label: 'th', value: 'Thai'},
        {label: 'vi', value: 'Vietnamese'},
        {label: 'da', value: 'Danish'},
        {label: 'no', value: 'Norwegian'},
        {label: 'pl', value: 'Polish'},
        {label: 'fi', value: 'Finnish'},
        {label: 'ko', value: 'Korean'},
        {label: 'he', value: 'Hebrew'},
        {label: 'ar', value: 'Arabic'}
    ],
    handlingReviews: [
        {label: 0, value: 'All reviews'},
        {label: 5, value: '5 star reviews'},
        {label: 4, value: '4 star reviews'},
        {label: 3, value: '3 star reviews'},
        {label: 'Never', value: 'Never'}
    ],
    reviewNotifications: [
        {value: 'Enable', label: 'Enable'},
        {value: 'Disable', label: 'Disable'}
    ],
    afterValue: [
        {value: 'Purchase', label: 'Purchase'},
        {value: 'Fulfillment', label: 'Fulfillment'},
        {value: 'Delivery', label: 'Delivery'}
    ],
    appearance: [
        {value: 'Boxed', label: 'Boxed'},
        {value: 'Modern', label: 'Modern'},
        {value: 'Custom', label: 'Custom'}
    ],
    discountType: [
        {label: 'No discount', value: 'No discount'},
        {label: 'Percentage', value: "Percentage (%)"},
        {label: 'Fixed', value: 'Fixed'}
    ],
    discountOffers: [
        {value: 'On-site & review requests', label: 'On-site & review requests'},
        {value: 'Review requests only', label: 'Review requests only'}
    ],
    photoReminders: [
        {label: '5-star', value: 'For reviews with 5 stars only'},
        {label: '4-star', value: 'For reviews with 4 stars and up'},
        {label: 'ask reviewer', value: "Ask each reviewer if they'd like a reminder"},
        {label: 'Never', value: 'Never'}
    ],
    reviewRequests: [
        {label: 'Everyone', value: 'Everyone'},
        {label: 'Customer consent', value: 'Customers who consent to receive marketing emails'}
    ],
    unsubscribe: [
        {label: 'does not unsubscribe', value: 'Does not unsubscribe the customer from shopify marketing emails'},
        {label: 'does unsubscribe', value: 'Unsubscribes the customer from shopify marketing emails'}
    ],
    GTIN: [
        {value: "Don't include", label: "Don't include"},
        {value: `Include from "Barcode"`, label: `Include from "Barcode"`},
        {value: `Include from "SKU"`, label: `Include from "SKU"`},
        {value: `Include from "Product ID"`, label: `Include from "Product ID"`}
    ],
    MPN: [
        {value: "Don't include", label: "Don't include"},
        {value: `Include from "Barcode"`, label: `Include from "Barcode"`},
        {value: `Include from "SKU"`, label: `Include from "SKU"`},
        {value: `Include from "Product ID"`, label: `Include from "Product ID"`}
    ],
    productPrefix: [
        {label: 'Shopify_US', value: 'Shopify_US'},
        {label: 'Do not add prefix', value: 'Do not add prefix'},
        {label: 'Shopify_GB', value: 'Shopify_GB'},
        {label: 'Shopify_FR', value: 'Shopify_FR'},
        {label: 'Shopify_AU', value: 'Shopify_AU'},
        {label: 'Shopify_CN', value: 'Shopify_CN'}
    ],
    font: [
        {value: 'Roboto', label: 'Roboto'},
        {value: 'Poppins', label: 'Poppins'}
    ],
    reviewShadow: [
        {value: 'Basic', label: 'Basic'},
        {value: 'Dark offset', label: 'Dark offset'},
        {value: 'Light offset', label: 'Light offset'},
        {value: 'No shadow', label: 'No shadow'}
    ],
    radius: [
        {value: 'Sharp', label: 'Sharp'},
        {value: 'Slightly rounded', label: 'Slightly rounded'},
        {value: 'Rounded', label: 'Rounded'},
        {value: 'Extra rounded', label: 'Extra rounded'}
    ],
    branding: [
        {value: 'Shown', label: 'Shown'},
        {value: 'Hidden', label: 'Hidden'}
    ],
    reviewsWidget: [
        {label: 'always shown', value: 'Always Shown'},
        {label: 'hidden', value: 'Hidden when empty'},
        {label: 'always hidden', value: 'Always Hidden'},
        {label: 'empty', value: 'All reviews when empty'},
        {label: 'always', value: 'All reviews always'}
    ],
    warButton: [
        {label: 'always shown', value: 'Always Shown'},
        {label: 'always hidden', value: 'Always Hidden'}
    ],
    defaultSorting: [
        {label: 'featured', value: 'Featured'},
        {label: 'newest', value: 'Newest'},
        {label: 'highest', value: 'Highest Ratings'},
        {label: 'lowest', value: 'Lowest Ratings'}
    ],
    position: [
        {label: 'bottom-left', value: 'Bottom Left Corner'},
        {label: 'bottom-right', value: 'Bottom Right Corner'},
        {label: 'top-left', value: 'Top Left Corner'},
        {label: 'top-right', value: 'Top Right Corner'}
    ],
    minimumRating: [
        {label: '5', value: '5 stars only'},
        {label: '4 up', value: '4 stars and up'},
        {label: '3 up', value: '3 stars and up'},
        {label: '2 up', value: '2 stars and up'},
        {label: 'All', value: 'All Reviews'}
    ],
    selectWidget: [
        {label: 'main', value: 'Main Widget'},
        {label: 'photoWall', value: 'Photo Wall'},
        {label: 'title', value: 'Title'},
        {label: 'reviewSite', value: 'Review Site'}
    ],
    reviewsPerPage: [
        { value: 1, label: 1 },
        { value: 2, label: 2 },
        { value: 3, label: 3 },
        { value: 4, label: 4 },
        { value: 5, label: 5 },
        { value: 6, label: 6 },
        { value: 7, label: 7 },
        { value: 8, label: 8 },
        { value: 9, label: 9 },
        { value: 10, label: 10 },
        { value: 11, label: 11 },
        { value: 12, label: 12 },
        { value: 13, label: 13 },
        { value: 14, label: 14 },
        { value: 15, label: 15 },
        { value: 16, label: 16 },
        { value: 17, label: 17 },
        { value: 18, label: 18 },
        { value: 19, label: 19 },
        { value: 20, label: 20 }
    ],
    emailTiming: [
        { value: '1 day', label: '1 day' },
        { value: '2 days', label: '2 days' },
        { value: '3 days', label: '3 days' },
        { value: '4 days', label: '4 days' },
        { value: '5 days', label: '5 days' },
        { value: '6 days', label: '6 days' },
        { value: '7 days', label: '7 days' },
        { value: '8 days', label: '8 days' },
        { value: '9 days', label: '9 days' },
        { value: '10 days', label: '10 days' },
        { value: '11 days', label: '11 days' },
        { value: '12 days', label: '12 days' },
        { value: '13 days', label: '13 days' },
        { value: '14 days', label: '14 days' },
        { value: '15 days', label: '15 days' },
        { value: '16 days', label: '16 days' },
        { value: '17 days', label: '17 days' },
        { value: '18 days', label: '18 days' },
        { value: '19 days', label: '19 days' },
        { value: '20 days', label: '20 days' },
        { value: '21 days', label: '21 days' },
        { value: '22 days', label: '22 days' },
        { value: '23 days', label: '23 days' },
        { value: '24 days', label: '24 days' },
        { value: '25 days', label: '25 days' },
        { value: '26 days', label: '26 days' },
        { value: '27 days', label: '27 days' },
        { value: '28 days', label: '28 days' },
        { value: '29 days', label: '29 days' },
        { value: '30 days', label: '30 days' },
        { value: '31 days', label: '31 days' },
        { value: '32 days', label: '32 days' },
        { value: '33 days', label: '33 days' },
        { value: '34 days', label: '34 days' },
        { value: '35 days', label: '35 days' },
        { value: '36 days', label: '36 days' },
        { value: '37 days', label: '37 days' },
        { value: '38 days', label: '38 days' },
        { value: '39 days', label: '39 days' },
        { value: '40 days', label: '40 days' },
        { value: '41 days', label: '41 days' },
        { value: '42 days', label: '42 days' },
        { value: '43 days', label: '43 days' },
        { value: '44 days', label: '44 days' },
        { value: '45 days', label: '45 days' },
        { value: '46 days', label: '46 days' },
        { value: '47 days', label: '47 days' },
        { value: '48 days', label: '48 days' },
        { value: '49 days', label: '49 days' },
        { value: '50 days', label: '50 days' },
        { value: '51 days', label: '51 days' },
        { value: '52 days', label: '52 days' },
        { value: '53 days', label: '53 days' },
        { value: '54 days', label: '54 days' },
        { value: '55 days', label: '55 days' },
        { value: '56 days', label: '56 days' },
        { value: '57 days', label: '57 days' },
        { value: '58 days', label: '58 days' },
        { value: '59 days', label: '59 days' },
        { value: '60 days', label: '60 days' },
        { value: '61 days', label: '61 days' },
        { value: '62 days', label: '62 days' },
        { value: '63 days', label: '63 days' },
        { value: '64 days', label: '64 days' },
        { value: '65 days', label: '65 days' },
        { value: '66 days', label: '66 days' },
        { value: '67 days', label: '67 days' },
        { value: '68 days', label: '68 days' },
        { value: '69 days', label: '69 days' },
        { value: '70 days', label: '70 days' },
        { value: 'Never', label: 'Never' }
    ],
    timezone: [
        {value: 'GMT (5:00)', label: 'GMT (5:00)'}
    ],
    productUnavailable: [
        {value: 'Do Nothing', label: 'Do Nothing'},
        {value: 'Set as draft', label: 'set draft'},
        {value: 'Set quantity to zero', label: 'zero quantity'},
        {value: 'Notify Me', label: 'notify me'}
    ],
    variantUnavailable: [
        {value: 'Do Nothing', label: 'Do Nothing'},
        {value: 'Remove variant', label: 'variant'},
        {value: 'Set quantity to zero', label: 'zero quantity'},
        {value: 'Notify Me', label: 'notify me'}
    ],
    costChange: [
        {value: 'Do Nothing', label: 'Do Nothing'},
        {value: 'Update price and compare at price', label: 'update and compare'},
        {value: 'Update price only', label: 'update price only'},
        {value: 'Notify Me', label: 'notify me'}
    ],
    inventoryChange: [
        {value: 'Do Nothing', label: 'Do Nothing'},
        {value: 'Update automatically', label: 'update automatically'}
    ]
}

export default options