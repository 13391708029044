import {createStore, applyMiddleware, combineReducers, compose} from 'redux';
import thunk from 'redux-thunk';
import templateReducer from "./templateReducer";
import widgetThemeReducer from "./widgetThemeReducer";
import settingsReducer from "./settings/settingsReducer";
import widgetLocaleReducer from "./widget-locale/widgetLocaleReducer";
import availableWidgetLocaleReducer from "./widget-locale/availableWidgetLocaleReducer";
import widgetDefaultEnglishLocaleReducer from "./widget-locale/widgetDefaultEnglishLocaleReducer";
import reviewGroupsReducer from "./review-groups/review-groups-reducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducers = combineReducers({
        emailTemplate: templateReducer,
        widgetTheme: widgetThemeReducer,
        settings: settingsReducer,
        widgetLocale: widgetLocaleReducer,
        availableWidgetLocales: availableWidgetLocaleReducer,
        defaultEnglishLocale: widgetDefaultEnglishLocaleReducer,
        reviewGroups: reviewGroupsReducer
})

export default createStore(rootReducers, composeEnhancers(applyMiddleware(thunk)));