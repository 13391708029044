import React from "react";

const FormBuilderConfigButton = ({ buttonTitle, Icon, className, onClick, iconSize = "larger" }) => {
    return <div className={`border rounded bg-light ${className}`}>
        <button className={"btn"} onClick={onClick}>
            <div className={"d-flex align-items-center"}>
                <Icon className={"me-2"} style={{fontSize: iconSize}} />
                <span>{buttonTitle}</span>
            </div>
        </button>
    </div>
}

export default FormBuilderConfigButton