
export default (state = {}, action)=>{
    switch (action.type) {
        case "set-locale":
            return action.data;
        case "update-locale-dictionary":
            return {
                ...state,
                ...action.change
            }
        default:
            return state;
    }
}
