import Cookies from 'js-cookie';

export function setItem(key, value){
    localStorage.setItem(key, value);
    Cookies.set(key, value);
}
export function getItem(key){
    return localStorage.getItem(key) || Cookies.get(key);
}

export function removeItem(key){
    localStorage.removeItem(key);
    Cookies.remove(key);
}
