import React from "react";
import {
    FormBuilderInputField,
    FormBuilderTextArea,
    FormBuilderDatePicker,
    FormBuilderDropdown,
    FormBuilderInputCR,
    FormBuilderSwitchBtn
} from "./data/FormBuilderFields";

const FormBuilderSectionLayout = ({ inputField, datepicker, dropdown, inputCR, textArea, switchBtn }) => {
    return <>
        {inputField.map(({ id, type, title, placeholder, mandatory }) => <div className={"col-12 col-md-6 mt-3"}>
            <FormBuilderInputField key={id} type={type} title={title} mandatory={mandatory} placeholder={placeholder} />
        </div>)}

        {datepicker.map(({ title, mandatory }) => <div className={"col-12 col-md-6 mt-3"}>
            <FormBuilderDatePicker title={title} mandatory={mandatory} />
        </div>)}

        {dropdown.map(({ className, title, placeholder, mandatory, option }) => <div className={"col-12 col-md-6 mt-3"}>
            <FormBuilderDropdown className={className} title={title} placeholder={placeholder} mandatory={mandatory} option={option} />
        </div>)}

        {textArea.map(({ title, className, mandatory, placeholder }) => <div className={"col-12 mt-3"}>
            <FormBuilderTextArea title={title} className={className} mandatory={mandatory} placeholder={placeholder} />
        </div>)}

        {inputCR.map(({ title, type, className }) => <div className={"col-12 col-md-6 mt-3"}>
            <FormBuilderInputCR title={title} type={type} className={className} />
        </div>)}

        {switchBtn.map(({ title }) => <div className={"col-12 col-md-6 mt-4"}>
            <FormBuilderSwitchBtn title={title} />
        </div>)}
    </>
}

export default FormBuilderSectionLayout

//Old Layout
//<section className={"p-4 border-top border-bottom border-3 border-dark"}>
//         <div className={"row"}>
//             <div className={"col-6"}>
//                 <FormBuilderInputField type={"text"} title={"Full Name"} mandatory={true} />
//             </div>
//
//             <div className={"col-6"}>
//                 <FormBuilderInputField type={"email"} title={"Email"} mandatory={true} />
//             </div>
//         </div>
//
//         <div className={"row mt-3"}>
//             <div className={"col-6"}>
//                 <FormBuilderInputField type={"number"} title={"Phone Number"} />
//             </div>
//
//             <div className={"col-6"}>
//                 <FormBuilderDatePicker title={"Date of Birth"} />
//             </div>
//         </div>
//
//         <div className={"row mt-3"}>
//             <div className={"col-6"}>
//                 <FormBuilderDropdown title={"Country"} option={options.discountType} placeholder={"Select your country"} />
//             </div>
//
//             <div className={"col-6"}>
//                 <FormBuilderDropdown title={"City"} option={options.discountType} placeholder={"Select your city"} />
//             </div>
//         </div>
//
//         <div className={"row mt-3"}>
//             <FormBuilderTextArea title={"Address"} />
//         </div>
//
//         <div className={"row mt-4"}>
//             <div className={"col-6"}>
//                 <FormBuilderInputCR type={"checkbox"} title={"Checkbox"} />
//             </div>
//
//             <div className={"col-6"}>
//                 <FormBuilderSwitchBtn title={"Switch"} />
//             </div>
//         </div>
//     </section>