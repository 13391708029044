import {
    BsTelephone, BsUiRadiosGrid,
    CgCalendarDates, FaRegAddressCard,
    GiModernCity, GiPoland,
    HiOutlineExternalLink, IoIosAttach,
    IoIosCheckbox, IoIosSwitch,
    MdEmail, RiMenuAddFill
} from "react-icons/all";
import {
    BsInputCursor,
    CgFormatText,
    BsCalendarDate
} from "react-icons/all";
import options from "../../pages/components/settings/data/Options";

const InputField = (type, title, className, placeholder, mandatory = false) => {
    return {
        id: Math.random() + 19,
        type,
        title,
        placeholder,
        mandatory,
        className
    }
}

const TextAreaField = (title, placeholder, className, mandatory = false) => {
    return {
        id: Math.random() + 29,
        title,
        placeholder,
        mandatory,
        className
    }
}

const DatePickerField = (title, mandatory) => {
    return {
        id: Math.random() + 39,
        title,
        mandatory
    }
}

const DropdownField = (title, placeholder, option, className, mandatory) => {
    return {
        id: Math.random() + 49,
        title,
        placeholder,
        className,
        mandatory,
        option
    }
}

const InputCRField = (type, title, className) => {
    return {
        id: Math.random() + 59,
        type,
        title,
        className
    }
}

const SwitchBtnField = (title) => {
    return {
        id: Math.random() + 69,
        title
    }
}

export const formSections = [
    {
        id: 1,
        title: "Section 1"
    }
]

export const formFields = [
    {
        inputField: [
            InputField("text", "Full Name", "John Doe", true),
            InputField("email", "Email", "Johndoe@email.com", true),
            InputField("number", "Phone Number", "+91 1234567890")
        ],
        datePicker: [
            DatePickerField("Date of Birth")
        ],
        dropdown: [
            DropdownField("Country", "Select your country", options.handlingReviews),
            DropdownField("City", "Select your city", options.handlingReviews)
        ],
        textArea: [
            TextAreaField("Address", "Enter your address")
        ],
        inputCR: [
            InputCRField("radio", "Radio Button"),
            InputCRField("checkbox", "Checkbox")
        ],
        switchBtn: [
            SwitchBtnField("On/Off")
        ]
    }
]

export const suggestedField = [
    {
        id: 1,
        title: "Full Name",
        icon: BsInputCursor
    },
    {
        id: 2,
        title: "Email",
        icon: MdEmail
    },
    {
        id: 3,
        title: "Phone Number",
        icon: BsTelephone,
        iconSize: "x-large"
    },
    {
        id: 4,
        title: "Date of Birth",
        icon: CgCalendarDates,
        iconSize: "x-large"
    },
    {
        id: 5,
        title: "Country",
        icon: GiPoland
    },
    {
        id: 6,
        title: "City",
        icon: GiModernCity
    },
    {
        id: 7,
        title: "Address",
        icon: FaRegAddressCard
    },
    {
        id: 8,
        title: "Zip Code",
        icon: BsInputCursor
    }
]

export const dragAndDropField = [
    {
        id: 1,
        title: 'Input Field',
        icon: BsInputCursor
    },
    {
        id: 2,
        title: "Text Area",
        icon: CgFormatText
    },
    {
        id: 3,
        title: 'Datepicker',
        icon: BsCalendarDate
    },
    {
        id: 4,
        title: "Checkbox",
        icon: IoIosCheckbox
    },
    {
        id: 5,
        title: 'Dropdown Menu',
        icon: RiMenuAddFill,
        iconSize: "x-large"
    },
    {
        id: 6,
        title: "Radio Button",
        icon: BsUiRadiosGrid,
        iconSize: "x-large"
    },
    {
        id: 7,
        title: 'Attachment',
        icon: IoIosAttach
    },
    {
        id: 8,
        title: "Hyperlink",
        icon: HiOutlineExternalLink
    },
    {
        id: 9,
        title: "Switch Button",
        icon: IoIosSwitch,
        iconSize: "x-large"
    }
]