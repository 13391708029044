export const availableLanguages = [
    {
        locale:'en',
        label:'English'
    },
    {
        locale:'es',
        label:'Español'
    },{
        locale:'fr',
        label:'French'
    },
    {
        locale:'de',
        label:'Deutsch'
    }
]
function loadLang(locale) {
    return import('./'+locale+".js").then((data)=>data.default)
}
const map = {};
for(let {locale, label} of availableLanguages) {
    map[locale] = function(){
        return loadLang(locale)
    }
}
export const localeMap = map;
